<template>
  <v-row class="match-height">
    <!-- horizontal -->
    <v-col cols="12" md="12">
      <v-card flat class="pa-3 mt-2">
        <v-form class="multi-col-validation">
          <v-card-text class="pt-5">
            <v-row>
              <v-col cols="12">
                <h3 class="font-weight-black">Kop Koperasi</h3>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="editedItem.parameter.kop_1" outlined dense label="Kop 1"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="editedItem.parameter.kop_2" outlined dense label="Kop 2"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="editedItem.parameter.kop_3" outlined dense label="Kop 3"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="editedItem.parameter.kop_4" outlined dense label="Kop 4"></v-text-field>
              </v-col>

              <v-col cols="12">
                <h3 class="font-weight-black">Tanggal Hitung Bunga Tabungan</h3>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  type="number"
                  v-model="editedItem.parameter.tanggal_bunga_tabungan"
                  outlined
                  dense
                  label="Tanggal Hitung Bunga Tabungan"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <h3 class="font-weight-black">Pengamanan</h3>
              </v-col>
              <v-col cols="6" md="3">
                <v-text-field
                  v-model="editedItem.parameter.password_default"
                  outlined
                  dense
                  label="Password Default"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="3">
                <v-text-field
                  v-model="editedItem.parameter.pin_default"
                  outlined
                  dense
                  label="Pin Default"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12">
                <h3 class="font-weight-black">Kode Akuntansi SHU & Bunga</h3>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Nama</th>
                        <th class="text-left">Akun Debit</th>
                        <th class="text-left">Akun Kredit</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>SHU</td>
                        <td>
                          <v-autocomplete
                            v-model="editedItem.parameter.akun_shu_debit"
                            :items="akuns"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                          ></v-autocomplete>
                        </td>
                        <td>
                          <v-autocomplete
                            v-model="editedItem.parameter.akun_shu_kas"
                            :items="akuns"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                          ></v-autocomplete>
                        </td>
                      </tr>
                      <tr>
                        <td>Bunga</td>
                        <td>
                          <v-autocomplete
                            v-model="editedItem.parameter.akun_bunga_debit"
                            :items="akuns"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                          ></v-autocomplete>
                        </td>
                        <td>
                          <v-autocomplete
                            v-model="editedItem.parameter.akun_bunga_kredit"
                            :items="akuns"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                          ></v-autocomplete>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>

              <v-col cols="12" md="12">
                <h3 class="font-weight-black">Kode Akuntansi Simpanan</h3>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Nama</th>
                        <th class="text-left">Jenis Upah</th>
                        <th class="text-left">Akun Debit</th>
                        <th class="text-left">Akun Kredit</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in editedItem.simpanan" :key="index">
                        <td>
                          {{ item.nama }}
                        </td>
                        <td>
                          {{ item.jenis_upah }}
                        </td>
                        <td>
                          <v-autocomplete
                            v-model="editedItem.simpanan[index].kode_akun_kas"
                            :items="akuns"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                          ></v-autocomplete>
                        </td>
                        <td>
                          <v-autocomplete
                            v-model="editedItem.simpanan[index].kode_akun"
                            :items="akuns"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                          ></v-autocomplete>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>

              <v-col cols="12" md="12">
                <h3 class="font-weight-black">Kode Akuntansi Tabungan</h3>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Nama</th>
                        <th class="text-left">Jenis Upah</th>
                        <th class="text-left">Akun Debit</th>
                        <th class="text-left">Akun Kredit</th>
                        <!-- <th class="text-left">Akun Bunga Debit</th>
                        <th class="text-left">Akun Bunga Kredit</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in editedItem.tabungan" :key="index">
                        <td>
                          {{ item.nama }}
                        </td>
                        <td>
                          {{ item.jenis_upah }}
                        </td>
                        <td>
                          <v-autocomplete
                            v-model="editedItem.tabungan[index].pos_kas_tabungan"
                            :items="akuns"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                          ></v-autocomplete>
                        </td>
                        <td>
                          <v-autocomplete
                            v-model="editedItem.tabungan[index].pos_tabungan"
                            :items="akuns"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                          ></v-autocomplete>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>

              <v-col cols="12" md="12">
                <h3 class="font-weight-black">Kode Akuntansi Pinjaman & Angsuran</h3>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Nama</th>
                        <th class="text-left">Jenis Upah</th>
                        <th class="text-left">Akun Debit</th>
                        <th class="text-left">Akun Kredit</th>
                        <th class="text-left">Akun Angsuran Debit</th>
                        <th class="text-left">Akun Angsuran Kredit1</th>
                        <th class="text-left">Akun Angsuran Kredit2</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in editedItem.pinjaman" :key="index">
                        <td>
                          {{ item.nama }}
                        </td>
                        <td>
                          {{ item.jenis_upah }}
                        </td>
                        <td>
                          <v-autocomplete
                            v-model="editedItem.pinjaman[index].pos_pinjaman_kas"
                            :items="akuns"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                          ></v-autocomplete>
                        </td>
                        <td>
                          <v-autocomplete
                            v-model="editedItem.pinjaman[index].pos_pinjaman"
                            :items="akuns"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                          ></v-autocomplete>
                        </td>
                        <td>
                          <v-autocomplete
                            v-model="editedItem.pinjaman[index].kode_akun_kas"
                            :items="akuns"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                          ></v-autocomplete>
                        </td>
                        <td>
                          <v-autocomplete
                            v-model="editedItem.pinjaman[index].kode_akun_piutang"
                            :items="akuns"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                          ></v-autocomplete>
                        </td>
                        <td>
                          <v-autocomplete
                            v-model="editedItem.pinjaman[index].kode_akun_pendapatan"
                            :items="akuns"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                          ></v-autocomplete>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>

              <v-col cols="12" md="12">
                <h3 class="font-weight-black">Kode Akuntansi Arisan</h3>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Jenis Upah</th>
                        <th class="text-left">Akun Kas</th>
                        <th class="text-left">Akun Non Kas</th>
                        <th class="text-left">Akun Bunga</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>HARIAN</td>
                        <td>
                          <v-autocomplete
                            v-model="editedItem.parameter.arisan_harian_kas"
                            :items="akuns"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                          ></v-autocomplete>
                        </td>
                        <td>
                          <v-autocomplete
                            v-model="editedItem.parameter.arisan_harian_nkas"
                            :items="akuns"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                          ></v-autocomplete>
                        </td>
                        <td>
                          <v-autocomplete
                            v-model="editedItem.parameter.arisan_harian_bunga"
                            :items="akuns"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                          ></v-autocomplete>
                        </td>
                      </tr>
                      <tr>
                        <td>MINGGUAN</td>
                        <td>
                          <v-autocomplete
                            v-model="editedItem.parameter.arisan_mingguan_kas"
                            :items="akuns"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                          ></v-autocomplete>
                        </td>
                        <td>
                          <v-autocomplete
                            v-model="editedItem.parameter.arisan_mingguan_nkas"
                            :items="akuns"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                          ></v-autocomplete>
                        </td>
                        <td>
                          <v-autocomplete
                            v-model="editedItem.parameter.arisan_mingguan_bunga"
                            :items="akuns"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                          ></v-autocomplete>
                        </td>
                      </tr>
                      <tr>
                        <td>BULANAN</td>
                        <td>
                          <v-autocomplete
                            v-model="editedItem.parameter.arisan_bulanan_kas"
                            :items="akuns"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                          ></v-autocomplete>
                        </td>
                        <td>
                          <v-autocomplete
                            v-model="editedItem.parameter.arisan_bulanan_nkas"
                            :items="akuns"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                          ></v-autocomplete>
                        </td>
                        <td>
                          <v-autocomplete
                            v-model="editedItem.parameter.arisan_bulanan_bunga"
                            :items="akuns"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                          ></v-autocomplete>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>

              <v-col cols="12" md="12">
                <h3 class="font-weight-black">Kode Toko Online</h3>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Jenis Upah</th>
                        <th class="text-left">Akun Kas</th>
                        <th class="text-left">Akun Non Kas</th>
                        <th class="text-left">Akun Bunga</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>HARIAN</td>
                        <td>
                          <v-autocomplete
                            v-model="editedItem.parameter.akun_toko_harian_kas"
                            :items="akuns"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                          ></v-autocomplete>
                        </td>
                        <td>
                          <v-autocomplete
                            v-model="editedItem.parameter.akun_toko_harian_nkas"
                            :items="akuns"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                          ></v-autocomplete>
                        </td>
                        <td>
                          <v-autocomplete
                            v-model="editedItem.parameter.akun_toko_harian_bunga"
                            :items="akuns"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                          ></v-autocomplete>
                        </td>
                      </tr>
                      <tr>
                        <td>MINGGUAN</td>
                        <td>
                          <v-autocomplete
                            v-model="editedItem.parameter.akun_toko_mingguan_kas"
                            :items="akuns"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                          ></v-autocomplete>
                        </td>
                        <td>
                          <v-autocomplete
                            v-model="editedItem.parameter.akun_toko_mingguan_nkas"
                            :items="akuns"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                          ></v-autocomplete>
                        </td>
                        <td>
                          <v-autocomplete
                            v-model="editedItem.parameter.akun_toko_mingguan_bunga"
                            :items="akuns"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                          ></v-autocomplete>
                        </td>
                      </tr>
                      <tr>
                        <td>BULANAN</td>
                        <td>
                          <v-autocomplete
                            v-model="editedItem.parameter.akun_toko_bulanan_kas"
                            :items="akuns"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                          ></v-autocomplete>
                        </td>
                        <td>
                          <v-autocomplete
                            v-model="editedItem.parameter.akun_toko_bulanan_nkas"
                            :items="akuns"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                          ></v-autocomplete>
                        </td>
                        <td>
                          <v-autocomplete
                            v-model="editedItem.parameter.akun_toko_bulanan_bunga"
                            :items="akuns"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                          ></v-autocomplete>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text>
            <v-btn color="primary" :loading="loading" class="me-3 mt-3" @click="simpan()"> Simpan </v-btn>
          </v-card-text>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import { mdiPencil, mdiContentSave } from '@mdi/js'

const apiRoot = process.env.VUE_APP_APIURL
export default {
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiContentSave,
      },
    }
  },
  data: () => ({
    editedItem: {
      parameter: {
        kop_1: '',
        kop_2: '',
        kop_3: '',
        kop_4: '',
        logo: '',
        tanggal_bunga_tabungan: '',
        password_default: '',
        pin_default: '',
        akun_shu_kas: '',
        akun_shu_debit: '',
        akun_bunga_debit: '',
        akun_bunga_kredit: '',
        arisan_harian_nkas: '',
        arisan_harian_kas: '',
        arisan_harian_bunga: '',
        arisan_mingguan_nkas: '',
        arisan_mingguan_kas: '',
        arisan_mingguan_bunga: '',
        arisan_bulanan_nkas: '',
        arisan_bulanan_kas: '',
        arisan_bulanan_bunga: '',
        akun_toko_harian_nkas: '',
        akun_toko_harian_kas: '',
        akun_toko_harian_bunga: '',
        akun_toko_mingguan_nkas: '',
        akun_toko_mingguan_kas: '',
        akun_toko_mingguan_bunga: '',
        akun_toko_bulanan_nkas: '',
        akun_toko_bulanan_kas: '',
        akun_toko_bulanan_bunga: '',
      },
      simpanan: [],
      tabungan: [],
      pinjaman: [],
    },
    akuns: [],
    loading: false,
  }),

  computed: {
    token() {
      return this.$store.getters.token
    },
  },
  created() {
    this.getAkunsFromApi()
  },
  methods: {
    getAkunsFromApi() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      axios
        .get(`${apiRoot}/api/Akun/getAll`, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            // console.log(response.data.data)
            response.data.data.forEach(element => {
              this.akuns.push({
                text: element.nomor + ' ' + element.nama,
                value: element.id,
              })
            })
            this.getDataFromApi()
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    getDataFromApi() {
      this.$store.dispatch('loading', true)

      const headers = {
        Authorization: this.token,
      }
      axios
        .get(`${apiRoot}/api/Dashboard/getParameter`, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            // this.desserts = response.data.data
            this.editedItem=Object.assign({}, response.data.data)
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    simpan() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('kop_1', this.editedItem.parameter.kop_1)
      form.append('kop_2', this.editedItem.parameter.kop_2)
      form.append('kop_3', this.editedItem.parameter.kop_3)
      form.append('kop_4', this.editedItem.parameter.kop_4)
      form.append('tanggal_bunga_tabungan', this.editedItem.parameter.tanggal_bunga_tabungan)
      form.append('password_default', this.editedItem.parameter.password_default)
      form.append('pin_default', this.editedItem.parameter.pin_default)
      form.append('akun_shu_debit', this.editedItem.parameter.akun_shu_debit)
      form.append('akun_shu_kas', this.editedItem.parameter.akun_shu_kas)
      form.append('akun_bunga_debit', this.editedItem.parameter.akun_bunga_debit)
      form.append('akun_bunga_kredit', this.editedItem.parameter.akun_bunga_kredit)

      form.append('arisan_harian_nkas', this.editedItem.parameter.arisan_harian_nkas)
      form.append('arisan_harian_kas', this.editedItem.parameter.arisan_harian_kas)
      form.append('arisan_harian_bunga', this.editedItem.parameter.arisan_harian_bunga)
      form.append('arisan_mingguan_nkas', this.editedItem.parameter.arisan_mingguan_nkas)
      form.append('arisan_mingguan_kas', this.editedItem.parameter.arisan_mingguan_kas)
      form.append('arisan_mingguan_bunga', this.editedItem.parameter.arisan_mingguan_bunga)
      form.append('arisan_bulanan_nkas', this.editedItem.parameter.arisan_bulanan_nkas)
      form.append('arisan_bulanan_kas', this.editedItem.parameter.arisan_bulanan_kas)
      form.append('arisan_bulanan_bunga', this.editedItem.parameter.arisan_bulanan_bunga)

      form.append('akun_toko_harian_nkas', this.editedItem.parameter.akun_toko_harian_nkas)
      form.append('akun_toko_harian_kas', this.editedItem.parameter.akun_toko_harian_kas)
      form.append('akun_toko_harian_bunga', this.editedItem.parameter.akun_toko_harian_bunga)
      form.append('akun_toko_mingguan_nkas', this.editedItem.parameter.akun_toko_mingguan_nkas)
      form.append('akun_toko_mingguan_kas', this.editedItem.parameter.akun_toko_mingguan_kas)
      form.append('akun_toko_mingguan_bunga', this.editedItem.parameter.akun_toko_mingguan_bunga)
      form.append('akun_toko_bulanan_nkas', this.editedItem.parameter.akun_toko_bulanan_nkas)
      form.append('akun_toko_bulanan_kas', this.editedItem.parameter.akun_toko_bulanan_kas)
      form.append('akun_toko_bulanan_bunga', this.editedItem.parameter.akun_toko_bulanan_bunga)

      this.editedItem.simpanan.forEach((element, index) => {
        form.append('simpanan[' + index + '][id]', element.id)
        form.append('simpanan[' + index + '][kode_akun_kas]', element.kode_akun_kas)
        form.append('simpanan[' + index + '][kode_akun]', element.kode_akun)
      })
      this.editedItem.tabungan.forEach((element, index) => {
        form.append('tabungan[' + index + '][id]', element.id)
        form.append('tabungan[' + index + '][pos_kas_tabungan]', element.pos_kas_tabungan)
        form.append('tabungan[' + index + '][pos_tabungan]', element.pos_tabungan)
        form.append('tabungan[' + index + '][pos_kas_bunga]', element.pos_kas_bunga)
        form.append('tabungan[' + index + '][pos_bunga]', element.pos_bunga)
      })
      this.editedItem.pinjaman.forEach((element, index) => {
        form.append('pinjaman[' + index + '][id]', element.id)
        form.append('pinjaman[' + index + '][pos_pinjaman_kas]', element.pos_pinjaman_kas)
        form.append('pinjaman[' + index + '][pos_pinjaman]', element.pos_pinjaman)
        form.append('pinjaman[' + index + '][kode_akun_kas]', element.kode_akun_kas)
        form.append('pinjaman[' + index + '][kode_akun_piutang]', element.kode_akun_piutang)
        form.append('pinjaman[' + index + '][kode_akun_pendapatan]', element.kode_akun_pendapatan)
      })
      axios
        .post(`${apiRoot}/api/Dashboard/saveParameter`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            // this.getDataFromApi()
            this.$store.dispatch('logout', response.data.message + '! ')
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
  },
}
</script>
